<template>
<ParallaxContainer tag='section' class='work-grid'>
    <header class='work-header'>
        <h3 class='vertical-text'>{{$route.name === 'work' ? 'Most Recent' : 'View More Work'}}</h3>
    </header>
    <ul class='work-grid-list' ref='workGrid' :style='workGridCSS'>
        <ParallaxContainer
            v-for='item in projects'  
            tag='li' 
            class='work-item' 
            :key='item.title'>
            <ParallaxContainer tag='a' :to='`/work/${item.id}`' class='work-link'>
                <picture 
                    class='work-picture' 
                    role='img' 
                    :aria-label='item.title'>
                    <source
                        v-for='img in item.thumbs'
                        :key='img.src'
                        :srcset='img.src'
                        :type='img.type'/>
                    <ParallaxElement 
                        tag='img'
                        loading='lazy'
                        :factor='-0.2'
                        :src='item.thumb.src'
                        :width='item.thumb.width'
                        :height='item.thumb.height'
                        :alt='item.title'
                        class='work-picture-img'/>
                </picture>
            </ParallaxContainer>
            <div class='work-caption'>
                <h3 class='work-item-title font-weight-bold' v-html='item.title'/>
                <p class='work-subtitle font-size-small'>{{item.subtitle}}</p>
            </div>
        </ParallaxContainer>
    </ul>
</ParallaxContainer>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from '@/utils/debounce';
import toJPG from '@/utils/toJPG';
import ParallaxContainer from '@/components/ParallaxContainer';
import ParallaxElement from '@/components/ParallaxElement';

export default {
    name: 'work-grid',
    components: {
        ParallaxContainer,
        ParallaxElement
    },
    data() {
        return {
            scale: 2
        };
    },
    computed: {
        ...mapGetters({
            work: 'projects'
        }),
        projects() {
            const all = this.work.map(p => {
                return {
                    ...p,
                    thumb: {
                        src: toJPG(p.thumb.src),
                        width: p.thumb.width,
                        height: p.thumb.height
                    },
                    thumbs: [{
                        src: p.thumb.src,
                        type: 'image/webp'
                    }, {
                        src: toJPG(p.thumb.src),
                        type: 'image/jpeg'
                    }]
                };
            });
            const filtered = all.filter(p => this.$route.params.id !== p.id);
            return this.$route.name === 'work' ? all : filtered;
        },
        workGridCSS() {
            const rows = Math.ceil(this.work.length * this.scale);
            return {
                gridTemplateRows: `repeat(${rows}, var(--row-h))`
            };
        }
    },
    methods: {        
        setScale() {
            this.scale = window.innerWidth < 1280 ? 2.66 : 2;
        },
        onResize() {
            this.setScale();
        }
    },
    mounted() {
        this.setScale();
        window.addEventListener('resize', debounce(this.onResize, 250));
    }
}
</script>

<style lang='scss'>

@import '../styles/mixins';
@import '../styles/vars';

.work-grid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 1;
    padding-left: vw(var(--gutter));
    padding-right: vw(var(--gutter));
    width: 100%;
    position: relative;
}

.work-grid > header {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
}

.work-grid .vertical-text {
    right: initial;
    top: 0;
    left: vw(calc(var(--gutter) * -1));
    width: vw(var(--gutter));
    display: flex;
    align-items: center;
    font-family: $font-weight-bold;
    writing-mode: vertical-lr;
    position: absolute;
    white-space: nowrap;
    @include base-font();
    @include bp(mobile) {
        position: relative;
        left: initial;
        width: auto;
        writing-mode: lr;
        display: block;
        justify-self: flex-start;
        margin-bottom: vw(var(--gutter));
    }
}

#page.work-id .work-grid {
    @include bp(mobile) {
        padding-top: vw(var(--gutter));
    }
    .vertical-text {
        @include base-font();
    }
}

.work-grid .work-grid-list {
    --height: 100;
    --row-h: calc(var(--height)/var(--dw) * 100vw);
    --rows: 7;
    --cols: 3;
    list-style: none;
    width: 100%;
    margin: 0 0 vw(calc(var(--height) * -1));
    padding: 0;
    display: grid;
    grid-gap: vw(0) vw(90);
    grid-template-columns: repeat(var(--cols), 1fr);
    @include bp(tablet) {
        --cols: 2;
        --height: 160;
    }
    @include bp(mobile) {
        --cols: 1;
        --height: 100;
    }
}

.work-grid .work-item {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
}

@for $n from 1 through 9 {
    $is-even: $n % 2 == 0;
    $rows: var(--rows);
    $thumb-small: calc(var(--rows) - 4);
    $thumb-large: calc(var(--rows) - 2);
    $thumb-size: if($is-even, $thumb-small, $thumb-large);
    $row-start: if($n <= 3, $n, initial);
    $row-end: if($is-even, calc(var(--rows) - 2), $rows);
    .work-item:nth-child(#{$n}) {
        grid-template-rows: repeat($rows, var(--row-h));
        grid-row-start: $row-start;
        grid-row-end: span $row-end;
        .work-link {
            grid-row: span $thumb-size;
        }
        @include bp(mobile) {
            $rows-mob: if($is-even, calc(var(--rows) - 2), $rows);
            grid-template-rows: repeat($rows-mob, var(--row-h));
        }

    }
    @if $n <= 3 {
        @include bp(tablet) {
            .work-item:nth-child(#{$n}) {
                grid-row-start: initial;
            }
        }
    }
    @if $n <= 2 {
        @include bp(tablet) {
            .work-item:nth-child(#{$n}) {
                grid-row-start: $n;
            }
        }
        @include bp(mobile) {
            .work-item:nth-child(#{$n}) {
                grid-row-start: initial;
            }
        }
    }
}

.work-grid .work-item-title {
    margin: 0;
    @include base-font(h4);
}

.work-grid .work-picture {
    margin: 0;
    background: hsl(var(--color-font-dark), 0.1);
    width: 100%;
    overflow: hidden;
    will-change: transform;
    position: relative;
    * {
        pointer-events: none;
    }
}

.work-grid .work-link {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: inherit;
}

.work-grid .work-caption {
    padding: vw(15) 0;
}

.work-grid .work-picture-img {
    margin: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.work-grid .work-item-title {
    margin: 0;
}

.work-grid .work-subtitle {
    margin: vw(5) 0;
}

</style>